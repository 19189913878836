<template>
    <parent-layout title="District Management">
        <v-tabs class="mb-4">
            <v-tab link :to="{ name: 'district-profile' }">District Profile</v-tab>
            <v-tab link :to="{ name: 'sis-imports-api-setup' }">SIS Imports</v-tab>
            <v-tab link :to="{ name: 'authentication-configs' }">Authentication</v-tab>
            <v-tab link :to="{ name: 'export-overview' }">Product Export</v-tab>
        </v-tabs>
        <router-view />
    </parent-layout>
</template>

<script>
import ParentLayout from '@/components/layout/ParentLayout'

export default {
    components: {
        ParentLayout,
    },
}
</script>
